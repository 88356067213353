import { session } from '../helpers/helpers';

const apiInstance = async (url, method = 'GET', body = null) => {
  const token = session.get('token'); // Получаем токен из сессии

  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': token ? `Bearer ${token}` : '', // Добавляем токен только если он есть
  };

  const options = {
    method,
    headers,
  };

  // Если метод не GET и есть body, добавляем его в запрос
  if (body && method !== 'GET') {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);

    // Проверка на успешность ответа
    if (!response.ok) {
      if (response.status === 401) {
        console.warn('Unauthorized access. Logging out...');
        session.remove('token'); // Очищаем сессию
        window.location.href = '/signin'; // Перенаправляем пользователя на страницу входа
        return;
      }

      const errorText = await response.text();
      const error = {
        status: response.status,
        statusText: response.statusText,
        responseBody: errorText,
      };
      console.error('API Error:', error); // Логируем более подробную информацию
      throw new Error(`Error ${error.status}: ${error.statusText}`);
    }

    // Преобразуем ответ в JSON
    return await response.json();
  } catch (error) {
    // Логируем ошибку и возвращаем ее
    console.error('API Error:', error);
    throw error;
  }
};

export { apiInstance };
