import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import Button from '../../components/Button/Button';

const RequestItemLeaveComment = ({ sendMessage }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [submittedValue, setSubmittedValue] = useState('');
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const formRef = React.useRef(null);
  const [form] = Form.useForm();

  const handleSubmit = () => {
    setClickedSubmit(true);
    sendMessage(currentValue)
      .then(() => {
        if (currentValue) {
          setSubmittedValue(currentValue);
          setCurrentValue('');
          form.resetFields();
          setClickedSubmit(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onChange = (e) => {
    setCurrentValue(e.target.value);
  };

  return (
    <div className="box request-leave-comment">
      <div className="text-black-medium-f22-l30">Залишити коментар</div>
      <Form form={form} ref={formRef}>
        <Form.Item name="Subject" rules={[{ required: true, message: 'Введіть текст' }]}>
          <TextArea rows={4} placeholder="Введіть текст" onChange={onChange} />
        </Form.Item>
        <Form.Item>
          <Button
            onClick={handleSubmit}
            type="submit"
            text="Надіслати"
            bg="pink"
            disabled={clickedSubmit}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default RequestItemLeaveComment;
