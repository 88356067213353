import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import InputText from '../../components/Input/InputText';
import { session } from '../../helpers/helpers';
import { apiInstance } from '../../utils/api';

const ConfirmPassword = () => {
  const token = session.get('token');
  const [email, setEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState();
  const [errorsInPost, setErrorsInPost] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const { push } = useHistory();

  const ROUTES = {
    PASSWORD: new URL(`${process.env.REACT_APP_API_URL}/auth/forgot-password`),
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onChange = (e) => {
    setEmail(e.target.value);
    setErrorsInPost([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Валидация email
    if (!validateEmail(email)) {
      setErrorsInPost(['Введіть коректний email.']);
      return;
    }

    apiInstance(ROUTES.PASSWORD, 'POST', { email: email })
      .then((response) => {
        setErrorsInPost([]);
        setIsSuccess(response.ok);
        // console.log(response);
        setShowMessage(true); // Показуємо повідомлення після успішної відправки
        // Сховати повідомлення через 10 секунд
        setTimeout(() => {
          setShowMessage(false);
        }, 13000);
      })
      .catch((err) => {
        setErrorsInPost(err.message);

        setShowMessage(true); // Показуємо повідомлення про помилку
        // Сховати повідомлення через 10 секунд
        setTimeout(() => {
          setShowMessage(false);
        }, 3000);
      });
  };

  return (
    <div className="signin">
      <div className="signin__col signin__col_form">
        <a href="/" className="signin__link">
          <img src="/images/logo.svg" alt="logo" />
        </a>

        <div className="signin__inner">
          <div className="text-black-bold-f40-l48">Скинути пароль?</div>
          <div className="text-grey-dark-normal-f16-l28">
            Нагадайте ваш email, щоб ми надіслали на нього листа-інструкцію зі зміни паролю.
          </div>

          <form onSubmit={handleSubmit} className="form recovery-password-form">
            <InputText
              id="recovery-email"
              label="Email:"
              onChange={onChange}
              error={!isEmpty(errorsInPost) && errorsInPost}
            />
            <Button type="submit" text="Зберегти" bg="pink" block />

            {/* Повідомлення, яке зникає через 10 секунд */}
            {showMessage && (
              <div className={isSuccess ? 'text-green-normal-f16-l28' : 'text-red-normal-f16-l28'}>
                {isSuccess ? 'Повідомлення надіслано' : 'Сталась помилка'}
              </div>
            )}
          </form>
        </div>
      </div>

      <div className="signin__col signin__col_banner" />
    </div>
  );
};

export default ConfirmPassword;
