import moment from 'moment';

export const RequestIncident = ({ formContentItem }) => {
  return (
    <>
      {formContentItem.SuWork(moment(), moment().add(+1, 'hours'))}
      {formContentItem.Symptoms}
    </>
  );
};

export default RequestIncident;
