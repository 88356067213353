import { Form, Input, Modal, Upload } from 'antd';
import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/uk';
import React, { useMemo, useState } from 'react';
import Button from '../../components/Button/Button';
import { convertBase64 } from '../../helpers/helpers';
import { apiInstance } from '../../utils/api';
import { currentDate } from './CommodityHelpers';

const CommodityMonthly = ({ activeKey }) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async ({
    zReport: zReportField,
    act: actField,
    tradeWithVat,
    tradeWithoutVat,
  }) => {
    setClickedSubmit(true);
    const zReportBase64 = await convertBase64(zReportField.file.originFileObj);
    const zReportFileName = zReportField.file.name;
    const actBase64 = await convertBase64(actField.file.originFileObj);
    const actFileName = actField.file.name;

    setTimeout(() => {
      apiInstance(
        `${process.env.REACT_APP_API_URL}/api/post-data?type=trade-turnover-mountly`,
        'POST',
        {
          requestType: activeKey,
          month: currentDate,
          tradeWithVat,
          tradeWithoutVat,
          zReport: zReportBase64,
          act: actBase64,
          zReport_file_name: zReportFileName,
          act_file_name: actFileName,
        }
      );
    }, 0);

    setSuccessModal(true);
    form.resetFields();
  };

  const renderCommodityFooter = useMemo(() => {
    return (
      <div
        className={cx(
          'commodity-form__footer-title',
          { success: success && !error },
          { error: error && !success }
        )}
      >
        {success && !error && 'Дані було успішно відправлено'}
        {error && !success && 'Заповніть усі поля'}
      </div>
    );
  }, [success, error]);

  return (
    <>
      <div className="commodity__title text-black-bold-f19-l33">
        Звіт за {moment().locale('ua').format('MMMM YYYY')}
      </div>
      <div className="commodity__text text-grey-normal-f16-l28">
        Будь ласка, заповніть усі необхідні дані для створення заявки.
      </div>

      <Form
        className="commodity-form"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        requiredMark={false}
      >
        <div className="commodity-form__row">
          <div className="commodity-form__col">
            <Form.Item
              name="tradeWithVat"
              label="Сумарний товарообіг за місяць(з ПДВ):"
              rules={[{ required: true, message: 'Сумарний товарообіг за місяць(з ПДВ)' }]}
            >
              <Input
                placeholder="Введіть суму"
                suffix={<span className="text-grey-normal-f16-l28">грн</span>}
                type="number"
              />
            </Form.Item>

            <Form.Item
              name="tradeWithoutVat"
              label="Сумарний товарообіг за місяць(без ПДВ): "
              rules={[
                { required: true, message: 'Введіть сумарний товарообіг за місяць (без ПДВ)' },
              ]}
            >
              <Input
                placeholder="Введіть суму"
                suffix={<span className="text-grey-normal-f16-l28">грн</span>}
                type="number"
              />
            </Form.Item>
          </div>

          <div className="commodity-form__col">
            <Form.Item
              name="zReport"
              label="Завантаження файлу z-звіту (pdf, png, jpg):"
              rules={[{ required: true, message: 'Завантажте файл' }]}
            >
              <Upload className="commodity-form__upload" maxCount={1}>
                Choose File
              </Upload>
            </Form.Item>

            <Form.Item
              name="act"
              label="Завантаження файлу акта (pdf, png, jpg)"
              rules={[{ required: true, message: 'Завантажте файл' }]}
            >
              <Upload className="commodity-form__upload" maxCount={1}>
                Choose File
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button type="submit" text="Надіслати" bg="pink" disabled={clickedSubmit}>
                Надіслати
              </Button>
            </Form.Item>

            <div className="commodity-form__footer">
              {(error || success) && renderCommodityFooter}
            </div>
          </div>
        </div>
      </Form>
      <Modal
        className="success-modal"
        visible={successModal}
        footer={null}
        onCancel={() => {
          setSuccessModal(false);
          setClickedSubmit(false);
        }}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Дякуємо</div>
          <div className="text-grey-dark-normal-f16-l28">Місячний товарообіг успішно надіслано</div>
        </div>
      </Modal>
    </>
  );
};

export default CommodityMonthly;
