import { Table } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LabelIcon from '../../components/LabelIcon/LabelIcon';

const columns = [
  {
    title: 'Запит',
    dataIndex: 'request',
    className: 'request',
  },
  // {
  //   title: 'Дата',
  //   dataIndex: 'date',
  //   className: 'date',
  // },
  {
    title: 'Тема',
    dataIndex: 'theme',
    className: 'theme',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    className: 'status',
  },
];

const workType = 'Запрос на работы';
const workTypeId = 'f8b0f38b-60fd-42d9-b87d-39e704ed6d27';
const serviceType = 'Запрос на обслуживание';
const serviceTypeId = 'd7982047-eef2-435d-bf58-758c818433e4';
const incidentType = 'Инцидент';

const renderTypeRequestIcons = (type) => {
  const types = [workTypeId, serviceTypeId, incidentType];
  const filterTypes = types.includes(type);

  if (!filterTypes) return <LabelIcon icon="service" bg="turquoise" />;

  const work = type === workTypeId;
  const service = type === serviceTypeId;
  const incident = type === incidentType;

  return (
    <>
      {work && <LabelIcon icon="work" bg="pink" />}
      {service && <LabelIcon icon="service" bg="purple" />}
      {incident && <LabelIcon icon="incident" bg="turquoise" />}
    </>
  );
};

const DashboardTable = ({ requests }) => {
  const { push } = useHistory();

  const handleClick = useCallback(
    (id) => {
      push(`/requests/${id}`);
    },
    [push]
  );

  const prepareRequestData = requests.map(({ Id, CreatedOn, Subject, Status, Category }) => ({
    id: Id,
    request: (
      <div className="dashboard-table-request default-table__cell-row">
        {renderTypeRequestIcons(Category.value)}
        <div className="dashboard-table-request__text text-black-normal-f16-l28">
          {Category.displayValue}
        </div>
      </div>
    ),
    // date: (
    //   <div className='text-grey-dark-normal-f16-l28'>{moment(CreatedOn).format('YYYY.MM.DD')}</div>
    // ),
    theme: <div className="text-grey-dark-normal-f16-l28">{Subject}</div>,
    status: <div className="text-grey-dark-normal-f16-l28">{Status.displayValue}</div>,
  }));

  return (
    <Table
      pagination={false}
      className="default-table request-table dashboard-table"
      columns={columns}
      dataSource={prepareRequestData}
      locale={{ emptyText: 'Дані Відсутні' }}
      onRow={(record) => {
        return {
          onClick: () => handleClick(record.id),
        };
      }}
    />
  );
};

export default DashboardTable;
