import { Modal, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Svg from '../../components/SVG/SVG';
import { session } from '../../helpers/helpers';
import { downloadFile, getBlobFromUrl } from '../../utils/urlImgToBlob';

const RequestItemAttachments = ({ request }) => {
  const token = session.get('token');
  const [loading, setLoading] = useState(false);
  const [blobContent, setBlobContent] = useState(null);
  const [attachmentFileModal, setAttachmentFileModal] = useState(false);
  const [imageBlobType, setImageBlobType] = useState(false);
  const attachmentName = request.Attachments[0].Name;
  const attachmentId = request.Attachments[0].Id; // Get the attachment ID

  const download = useCallback((id) => {
    const ROUTES = {
      FILE: new URL(`${process.env.REACT_APP_API_URL}/api/files?fileId=${id}&type=case`),
    };
    return downloadFile(ROUTES.FILE, attachmentName); // Pass the attachment name to downloadFile
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (attachmentId) {
          setLoading(true);
          const blob = await getBlobFromUrl(
            `${process.env.REACT_APP_API_URL}/api/files?fileId=${attachmentId}&type=case`,
            token
          );

          // Create a data URL from the blob
          const reader = new FileReader();
          reader.onloadend = () => {
            setBlobContent(reader.result);
          };
          reader.readAsDataURL(blob);

          if (blob.type === 'image/jpeg' || blob.type === 'image/png') {
            setImageBlobType(true);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [attachmentId]);

  const handleClickAttachmentFile = () => {
    setAttachmentFileModal(true);
  };

  return (
    <div className="request-info-item">
      <div className="text-black-normal-f12-l17">Файл</div>
      <div className="text-black-normal-f16-l28">
        <div className="request-attachment-file">
          {loading ? (
            <Spin />
          ) : imageBlobType ? (
            <>
              <img
                className="request-attachment-img"
                src={blobContent}
                alt={attachmentName}
                onClick={handleClickAttachmentFile}
              />

              <Modal
                className="modal-type-green-close attachment-file-modal"
                visible={attachmentFileModal}
                footer={null}
                onCancel={() => setAttachmentFileModal(false)}
                closeIcon={<Svg name="close-modal" />}
              >
                <div className="modal-type-green-close__wrapper">
                  <img className="request-attachment-img" src={blobContent} alt={attachmentName} />
                </div>
              </Modal>
            </>
          ) : (
            <div onClick={() => download(attachmentId)}>
              <Svg className="download" name="download" />
              {attachmentName} {/* Display the attachment name */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestItemAttachments;
