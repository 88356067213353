import moment from 'moment';

export const convertBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const parseCookie = () => {
  const cookieArr = document.cookie.split(';');
  return cookieArr.reduce((acc, item) => {
    const [key, value] = item.trim().split('=');
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export const session = {
  set: (key, value, remember) => {
    const date = moment().add(30, 'days');
    document.cookie = `${key}=${value};expires=${date};path=/`;
  },
  get: (key) => {
    const cookie = parseCookie();
    return cookie[key];
  },
  remove: (key) => {
    const date = new Date('2007-07-07T07:07:07');
    document.cookie = `${key}=;expires=${date.toUTCString()};path=/`;
    // localStorage.removeItem(key);
  },
};

export const excludeRoute = ['/signin', '/recovery-password', '/restore-password'];
