import { Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Svg from '../../components/SVG/SVG';
import { session } from '../../helpers/helpers';
import { useContactStore } from '../../hooks/useContactStore';
import { apiInstance } from '../../utils/api';

const columns = [
  {
    title: 'ID запиту',
    dataIndex: 'number',
    className: 'id',
  },
  {
    title: 'Категорія запиту',
    dataIndex: 'category',
    className: 'category',
  },
  {
    title: 'Тема запиту',
    dataIndex: 'subject',
    className: 'type',
  },
  {
    title: 'Статус виконання',
    dataIndex: 'status',
    className: 'status',
  },
  {
    title: 'Детальніше про запит',
    dataIndex: 'more',
    className: 'more',
  },
];

const RequestTable = () => {
  const [requests, setRequests] = useState([]);
  const { push } = useHistory();
  const { currentContactStore } = useContactStore();
  const token = session.get('token');

  const handleClick = useCallback(
    (id) => {
      push(`/requests/${id}`);
    },
    [push]
  );

  const ROUTES = {
    REQUESTS: `${process.env.REACT_APP_API_URL}/api/crm/get-data?id=38807abb-8304-474d-9053-c341247981f5&type=requests`,
  };

  useEffect(() => {
    apiInstance(ROUTES.REQUESTS, 'GET').then((response) => {
      // console.log('API Response:', response);
      if (response?.rows && Array.isArray(response.rows)) {
        setRequests(response.rows);
      } else {
        console.error('Invalid response format: rows is not an array');
      }
    });
  }, [currentContactStore, token]);

  const prepareRequestData = requests.map(({ Number, Id, Category, Subject, Status }) => ({
    id: Id,
    number: <span>{Number}</span>,
    category: Category ? Category.displayValue : 'Невідомо', // Проверка на наличие данных
    subject: Subject,
    status: Status ? Status.displayValue : 'Невідомо',
    more: <Svg name="see-more" />,
  }));

  return (
    <Table
      className="default-table request-table"
      columns={columns}
      dataSource={prepareRequestData}
      locale={{ emptyText: 'Дані відсутні' }}
      onRow={(record) => {
        return {
          onClick: () => handleClick(record.id),
        };
      }}
    />
  );
};

export default RequestTable;
