import { session } from '../helpers/helpers';

const token = session.get('token');

export const getBlobFromUrl = (myImageUrl) => {
  // console.log('getBlobFromUrl started, URL:', myImageUrl);
  return new Promise((resolve, reject) => {
    fetch(myImageUrl, { method: 'GET', headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        // console.log('getBlobFromUrl: fetch successful, status:', response.status);
        if (!response.ok) {
          // console.error('getBlobFromUrl: fetch failed, status:', response.status);
          reject(new Error(`Failed to fetch: ${response.status} ${response.statusText}`));
          return;
        }
        response.blob().then((data) => {
          // console.log('getBlobFromUrl: blob received, size:', data.size);
          resolve(data);
        });
      })
      .catch((err) => {
        // console.error('getBlobFromUrl: fetch failed:', err);
        reject(err);
      });
  });
};

export const getDataFromBlob = async (myBlob, filename) => {
  // console.log('getDataFromBlob started, filename:', filename);
  try {
    const result = await new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = () => reject(new Error('Error reading the file'));
      fileReader.readAsDataURL(myBlob);
    });

    // console.log('getDataFromBlob: file read successfully');

    const contentType = myBlob.type;
    // console.log('getDataFromBlob: content type:', contentType);

    // Кодируем имя файла
    // const encodedFilename = encodeURIComponent(filename);
    // console.log('getDataFromBlob: encoded filename:', encodedFilename);

    // Создаем ссылку для скачивания
    const a = document.createElement('a');
    a.href = result;

    // Декодируем имя файла перед установкой атрибута download
    a.download = decodeURIComponent(filename);

    a.click(); // Инициируем скачивание

    // console.log('getDataFromBlob: file download initiated');
  } catch (err) {
    console.error('getDataFromBlob: error:', err);
  }
};

const downloadFile = async (imageURL, filename = 'receipt') => {
  // Add default filename
  try {
    // console.log('downloadFile started, URL:', imageURL, 'filename:', filename);
    const blob = await getBlobFromUrl(imageURL);
    await getDataFromBlob(blob, filename);
    // console.log('downloadFile: file download initiated');
  } catch (err) {
    console.error('downloadFile: error:', err);
  }
};

export { downloadFile };
