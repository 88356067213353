import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Svg from '../../components/SVG/SVG';
import { AuthContext } from '../../Guard/AuthContext';
import { session } from '../../helpers/helpers';
import { RequestContext } from '../../helpers/requestContext';
import { useContactStore } from '../../hooks/useContactStore';
import { apiInstance } from '../../utils/api';
import DashboardTable from './DashboardTable';

const dashboardRequestList = [
  {
    route: '/create-request?d7982047-eef2-435d-bf58-758c818433e4',
    icon: 'request-statement',
    text: 'Запит до адміністрації',
    btnBg: 'orange',
  },
  {
    route: '/create-request?f8b0f38b-60fd-42d9-b87d-39e704ed6d27',
    icon: 'work',
    text: 'Запит до експлуатації',
    btnBg: 'pink',
  },
  {
    route: '/create-request?2a92dbea-2b8c-4a63-b3f6-ea4135f981d7',
    icon: 'others',
    text: 'Видача карти СКД',
    btnBg: 'purple',
  },
];

const DashboardItem = ({ dashboardRequesItem, setCreateRequestFromDashboard }) => {
  const { icon, text, btnBg, route } = dashboardRequesItem;
  const { push } = useHistory();

  const createHandler = () => {
    setCreateRequestFromDashboard(true);
    push(route);
  };
  return (
    <div className="dashboard-request-item">
      <Svg name={icon} />
      <div className="dashboard-request-item__text text-black-normal-f16-l28">{text}</div>
      <Button text="Сформувати" bg={btnBg} onClick={() => createHandler()} />
    </div>
  );
};

const Dashboard = () => {
  const [requests, setRequests] = useState([]);
  // const [balance, setBalance] = useState([]);
  const token = session.get('token');
  const { currentContactStore } = useContactStore();

  // console.log('currentContactStore', currentContactStore);

  const ROUTES = {
    REQUESTS: new URL(
      `${process.env.REACT_APP_API_URL}/api/crm/get-data?id=4735643-asjhdg&type=requests`
    ),
    // BALANCE: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/user/balance`),
  };

  const { user } = useContext(AuthContext);

  const { setCreateRequestFromDashboard } = useContext(RequestContext);

  useEffect(() => {
    apiInstance(ROUTES.REQUESTS, 'GET')
      .then((response) => {
        // console.log('API Response:', response);

        // Проверяем, что response и rows существуют и являются массивом
        if (response?.rows && Array.isArray(response.rows)) {
          setRequests(response.rows.slice(0, 6)); // Устанавливаем первые 6 записей
        } else {
          console.error('Invalid response format: rows is not an array');
          setRequests([]); // Устанавливаем пустой массив, если данных нет
        }
      })
      .catch((error) => {
        console.error('Error fetching requests:', error);
        setRequests([]); // Устанавливаем пустой массив при ошибке
      });
  }, [currentContactStore, token]);

  const newStatus = 'ae5f2f10-f46b-1410-fd9a-0050ba5d6c38';
  const calcNewRequests = requests.filter(({ Status }) => Status.value === newStatus).length;

  const dashboardRequestCreateBlock = useMemo(() => {
    return (
      <div className="box dashboard-request">
        <div className="dashboard-request__title text-black-medium-f22-l30">Сформувати запит</div>

        <div className="dashboard-request__list">
          {dashboardRequestList.map((dashboardRequesItem) => (
            <DashboardItem
              dashboardRequesItem={dashboardRequesItem}
              setCreateRequestFromDashboard={setCreateRequestFromDashboard}
            />
          ))}
        </div>

        <div className="dashboard-request__description text-grey-dark-normal-f16-l28">
          <span className="text-grey-dark-bold-f16-l28">Зверніть увагу:</span>
          адміністрація погоджує лише запити на ввезезення / вивіз товару та нічні роботи. Запити
          щодо інцидентів та скарг вирішує служба експлуатації. У інших випадках запити обробляються
          комерційною службою або відділом маркетингу.
        </div>
      </div>
    );
  }, [user]);

  const dashboardRequestListBlock = useMemo(() => {
    return (
      <div className="boxes__col right">
        {!requests.length ? (
          <div className="box dashboard-messages loading">
            <div className="dashboard-messages__text text-grey-dark-normal-f16-l28">
              Немає запитів
            </div>
          </div>
        ) : (
          <div className="box dashboard-messages">
            <div className="dashboard-messages__title text-black-normal-f22-l30">
              Сповіщення / відповіді на запити
            </div>
            <div className="dashboard-messages__text text-grey-dark-normal-f16-l28">
              {calcNewRequests} нових
            </div>

            <DashboardTable requests={requests} />
          </div>
        )}
      </div>
    );
  }, [requests]);

  const dashboardBlock = useCallback(
    (user) => {
      return (
        <div className="dashboard">
          <div className="boxes">
            <div className="boxes__col left">
              <div className="box dashboard-support">
                <div className="dashboard-support__title text-black-medium-f22-l30">
                  Маєте запитання?
                </div>
                <div className="dashboard-support__text text-grey-dark-normal-f16-l28">
                  Зв'яжіться з вашим персональним менеджером ТРЦ Respublika Park
                </div>

                <div className="dashboard-support__inner">
                  <div className="dashboard-support__name text-purple-dark-medium-f16-l20">
                    Телефон по загальним питанням
                  </div>
                  <div className="dashboard-support__number text-pink-medium-f16-l20">
                    +38 050 321 52 43
                  </div>
                </div>
              </div>

              {currentContactStore?.requests !== false && dashboardRequestCreateBlock}
            </div>

            {dashboardRequestListBlock}
          </div>
        </div>
      );
    },
    [user, requests]
  );

  if (!user) {
    return dashboardBlock();
  }

  return dashboardBlock(user);
};

export default Dashboard;
