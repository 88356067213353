import { Checkbox, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { LoadingZone } from './LoadingZone';
import { MarketingFileUpload } from './MarketingFileUpload';
import RequestSuWorkField from './RequestSuWorkField';

const { Option } = Select;
export const prepareSelectOptions = (list = []) => {
  return list.map(({ Name, Id }) => ({
    text: Name,
    value: Id,
  }));
};

export const PrepareSelect = ({ onChange, options, placeholder, defaultValue, disabledForm }) => {
  return (
    <Select
      defaultValue={defaultValue}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      loading={!options.length}
      disabled={disabledForm}
    >
      {options.map(({ value, text }) => (
        <Option key={value}>{text}</Option>
      ))}
    </Select>
  );
};

function InfoCircleOutlined() {
  return null;
}

const RequestFormContentItem = ({ form, availableRequestFields, references, clickedSubmit }) => {
  const currentCategoryValue =
    availableRequestFields?.Type?.value ?? form.getFieldValue('Category');
  const currentServiceCategory =
    availableRequestFields?.Category?.value ?? form.getFieldValue('ServiceCategory');

  return {
    SuWork: (SuStartWork, SuEndWork) => (
      <RequestSuWorkField
        SuStartWork={SuStartWork}
        SuEndWork={SuEndWork}
        form={form}
        clickedSubmit={clickedSubmit}
        currentCategoryValue={currentCategoryValue}
        currentServiceCategory={currentServiceCategory}
      />
    ),
    ServiceCategory: (
      <Form.Item
        name="ServiceCategory"
        label="Категорія сервісу"
        rules={[{ required: true, message: 'Вкажіть категорію сервісу' }]}
      >
        <PrepareSelect
          options={prepareSelectOptions(references?.ServiceCategory)}
          placeholder="Обрати"
          disabledForm={clickedSubmit}
        />
      </Form.Item>
    ),
    SuServiceSubCategory: (
      <Form.Item
        name="SuServiceSubCategory"
        label="Підкатегорія сервісу"
        rules={[{ required: true, message: 'Вкажіть підкатегорію сервісу' }]}
      >
        <PrepareSelect
          defaultValue={availableRequestFields?.SuServiceSubCategory?.value}
          options={prepareSelectOptions(references?.SuServiceSubCategory)}
          placeholder="Обрати"
          disabledForm={clickedSubmit}
        />
      </Form.Item>
    ),
    Symptoms: (
      <Form.Item name="Symptoms" label="Коментарі до запиту">
        <TextArea placeholder="Опишіть запит" disabled={clickedSubmit} />
      </Form.Item>
    ),
    SuPremise: (
      <Form.Item
        name="SuPremise"
        label="Виконавець"
        rules={[{ required: true, message: 'Вкажіть виконавця' }]}
      >
        <PrepareSelect
          defaultValue={availableRequestFields?.SuPremise?.value}
          options={prepareSelectOptions(references?.SuPremise)}
          placeholder="Обрати"
          disabledForm={clickedSubmit}
        />
      </Form.Item>
    ),
    SuClaimCategory: (
      <Form.Item
        name="SuClaimCategory"
        label="Категорія скарги"
        rules={[{ required: true, message: 'Вкажіть категорію скарги' }]}
      >
        <PrepareSelect
          defaultValue={availableRequestFields?.SuClaimCategory?.value}
          options={prepareSelectOptions(references?.SuClaimCategory)}
          placeholder="Обрати"
          disabledForm={clickedSubmit}
        />
      </Form.Item>
    ),
    SuClaimDepartment: (
      <Form.Item
        name="SuClaimDepartment"
        label="Департамент"
        rules={[{ required: true, message: 'Вкажіть департамент' }]}
      >
        <PrepareSelect
          defaultValue={availableRequestFields?.SuClaimDepartment?.value}
          options={prepareSelectOptions(references?.SuClaimDepartment)}
          placeholder="Обрати"
          disabledForm={clickedSubmit}
        />
      </Form.Item>
    ),
    SuCarNumber: (
      <Form.Item
        name="SuCarNumber"
        label="Номер авто:"
        tooltip={{
          title: 'для автомобілів, які перебуватимуть на паркінгу від 30 до 60 хв',
          icon: <InfoCircleOutlined />,
        }}
      >
        <Input placeholder="Вкажіть номер авто" disabled={clickedSubmit} />
      </Form.Item>
    ),
    SuTenantResonsible: (
      <Form.Item
        name="SuTenantResonsible"
        label="Відповідальна особа орендаря"
        rules={[{ required: true, message: 'Вкажіть відповідальну особу орендаря' }]}
      >
        <Input placeholder="Вкажіть відповідальну особу орендаря" disabled={clickedSubmit} />
      </Form.Item>
    ),
    SuTelNumberTenant: (
      <Form.Item
        name="SuTelNumberTenant"
        label="Телефон відповідальної особи"
        rules={[{ required: true, message: 'Вкажіть телефон відповідальної особи' }]}
      >
        <Input placeholder="Вкажіть телефон відповідальної особи" disabled={clickedSubmit} />
      </Form.Item>
    ),
    SuLoadingZone: (
      <LoadingZone
        defaultValue={availableRequestFields?.SuLoadingZone?.value}
        requests={references}
        clickedSubmit={clickedSubmit}
        initialLoadingZone={form.getFieldValue('SuLoadingZone')}
      />
    ),
    RespNightWork: (
      <Form.Item
        name="RespNightWork"
        label="Виконавці робіт"
        rules={[{ required: true, message: 'Вкажіть виконавців робіт' }]}
      >
        <Input placeholder="Вкажіть виконавців робіт" />
      </Form.Item>
    ),
    MarketingFile: <MarketingFileUpload disabled={clickedSubmit} />,
    SuWorkDescription: (
      <Form.Item
        name="SuWorkDescription"
        label="Опис робіт"
        rules={[{ required: true, message: 'Вкажіть опис робіт' }]}
      >
        <Input placeholder="Вкажіть опис робіт" disabled={clickedSubmit} />
      </Form.Item>
    ),
    Agreement: (
      <Form.Item
        className="ant-form-item__checkbox"
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Обовʼязкове поле')),
          },
        ]}
      >
        <Checkbox>
          <span className="field__label">
            Я ознайомлений і погоджуюся з
            <a
              className="text-turquoise-normal-f16-l28"
              href="https://cms.respublikapark.com.ua/uploads/Pravila_TR_Cz_Respublika_Park_938d233bec.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              правилами{' '}
            </a>
            проведення робіт
          </span>
        </Checkbox>
      </Form.Item>
    ),
  };
};

export default RequestFormContentItem;
