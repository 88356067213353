import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import InputText from '../../components/Input/InputText';
import { AuthContext } from '../../Guard/AuthContext';
import { session } from '../../helpers/helpers';
import { apiInstance } from '../../utils/api';

const ROUTES = {
  LOGIN: new URL(`${process.env.REACT_APP_API_URL}/auth/local`),
  VERIFY: new URL(`${process.env.REACT_APP_API_URL}/api/users/me`),
};

const Signin = () => {
  const { setLoading } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const { push } = useHistory();

  useEffect(() => {
    const token = session.get('token');

    if (token) {
      // Если токен валиден, перенаправляем на главную страницу
      push('/');
    }
  }, [push]);

  const login = async (e) => {
    e.preventDefault();

    const eventTargetElements = Array.from(e.target.elements);
    const payload = eventTargetElements.reduce((acc, { value, name }) => {
      if (name) {
        acc[name] = value;
      }
      return acc;
    }, {});

    try {
      const data = await apiInstance(ROUTES.LOGIN, 'POST', payload);

      if (data?.jwt) {
        session.set('token', data.jwt); // Сохраняем токен в сессии
        push('/'); // Перенаправление
      } else {
        setError(true); // Если нет токена, показываем ошибку
      }
    } catch (error) {
      setError(true); // Если ошибка при запросе, показываем ошибку
    }
  };

  return (
    <div className="signin">
      <div className="signin__col signin__col_form">
        <a href="/" className="signin__link">
          <img src="/images/logo.svg" alt="logo" />
        </a>

        <div className="signin__inner">
          <div className="text-black-bold-f40-l48">Увійти</div>
          <div className="text-grey-dark-normal-f16-l28">
            Будь ласка, авторизуйтесь, щоб увійти до кабінету орендаря.
          </div>

          <form onSubmit={(e) => login(e)} className="signin-form form">
            <InputText label="Email" id="identifier" />

            <InputText type="password" label="Пароль" id="password" />

            <div className="signin-form__wrapper">
              <Checkbox checked={active} onClick={() => setActive(!active)} label="Запам’ятати" />

              <Link to="/recovery-password" className="text-turquoise-normal-f16-l28">
                Не пам’ятаю пароль
              </Link>
            </div>

            <Button type="submit" text="Увiйти" bg="pink" />
          </form>

          {error && <div className="text-red-normal-f16-l28">Не правильний логін чи пароль</div>}

          <div className="text-grey-dark-normal-f16-l28">
            Хочете орендувати площу в ТРЦ Respublika Park?
          </div>
          <a className="text-turquoise-normal-f16-l28" href="/">
            Дізнайтесь як.
          </a>
        </div>
      </div>

      <div className="signin__col signin__col_banner" />
    </div>
  );
};

export default Signin;
