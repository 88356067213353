import { createContext, memo, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { excludeRoute, session } from '../helpers/helpers';
import mainRoutes from '../helpers/mainRoutes';
import { apiInstance } from '../utils/api';

export const AuthContext = createContext();

const ROUTES = {
  USER: new URL(`${process.env.REACT_APP_API_URL}/api/users/me?populate=*`),
  CURRENT_CONTACT_STORE: (crmId) =>
    `${process.env.REACT_APP_API_URL}/api/contact-stores?filters[crmId][$eq]=${crmId}&populate=*`,
};

const AuthProvider = ({ children, history }) => {
  const [user, setUser] = useState(null);
  const [contactStores, setContactStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = session.get('token');
  const [currentContactStore, setCurrentContactStore] = useState({});

  const isSigninPages = excludeRoute.includes(history.location.pathname);
  const isHomePage = history.location.pathname === '/' && !token;
  const mainRoutesPatnames = mainRoutes.map(({ path }) => path).includes(history.location.pathname);

  // Запрос за текущим пользователем
  useEffect(() => {
    if (token) {
      apiInstance(ROUTES.USER, 'GET', null)
        .then((fetchedUser) => {
          setUser(fetchedUser);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [token]);

  // Запрос за контактными магазинами после получения пользователя
  useEffect(() => {
    const userContactStores = user?.contactStores;
    if (userContactStores && userContactStores.length > 0) {
      const filters = userContactStores
        .map((store, index) => `filters[crmId][${index}]=${store.crmId}`)
        .join('&');
      const url = `${process.env.REACT_APP_API_URL}/api/contact-stores?${filters}`;

      apiInstance(url, 'GET', null)
        .then((response) => {
          const fetchedStores = response.data.map((item) => {
            const crmId = item.attributes.crmId;
            const matchedStore = userContactStores.find((cs) => cs.crmId === crmId);

            return {
              ...matchedStore,
              ...item.attributes,
              current: matchedStore?.current || false,
            };
          });
          // console.log('fetchedStores:', fetchedStores);
          setContactStores(fetchedStores);
        })
        .catch((error) => {
          console.error('Ошибка при получении контактных магазинов:', error);
        });
    } else {
      setContactStores([]);
    }
  }, [user]);

  // Обновление текущего контактного магазина с сервера
  const updateCurrentContactStore = (store) => {
    if (store) {
      const url = ROUTES.CURRENT_CONTACT_STORE(store.crmId);

      apiInstance(url, 'GET', null).then((response) => {
        if (response.data.length > 0) {
          // Получаем полный объект контактного магазина
          const updatedStore = {
            ...store, // Объединяем данные из contactStores
            ...response.data[0].attributes, // и данные из ответа сервера
          };
          setCurrentContactStore(updatedStore);
        } else {
          setCurrentContactStore({});
        }
      });
    } else {
      setCurrentContactStore({});
    }
  };

  useEffect(() => {
    const currentStore = contactStores.find((store) => store.current);
    updateCurrentContactStore(currentStore);
  }, [contactStores]);

  useEffect(() => {
    if (isSigninPages || isHomePage || !mainRoutesPatnames) {
      setLoading(false);
    }
  }, [user, history, isSigninPages, isHomePage, mainRoutesPatnames]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loading,
        setLoading,
        token,
        setCurrentContactStore: (store) => {
          updateCurrentContactStore(store);
        },
        currentContactStore,
        contactStores,
        setContactStores,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default withRouter(memo(AuthProvider));
