import { Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import InputText from '../../components/Input/InputText';
import { session } from '../../helpers/helpers';
import { apiInstance } from '../../utils/api';

const ChangePassword = () => {
  const token = session.get('token');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorsInPost, setErrorsInPost] = useState('');
  const [isSuccess, setIsSuccess] = useState('');
  const [showMismatchError, setShowMismatchError] = useState(false);
  const [showPasswordHint, setShowPasswordHint] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null); // Для перевірки токена
  const { push } = useHistory();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const getQuery = query.get('code');

  const ROUTES = {
    PASSWORD: new URL(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`),
    CHECK_TOKEN: new URL(`${process.env.REACT_APP_API_URL}/api/check/reset-password/${getQuery}`), // URL для перевірки токена
  };

  useEffect(() => {
    if (!!token) {
      push('/signin');
    }
    // Перевіряємо токен при монтажі компонента
    apiInstance(ROUTES.CHECK_TOKEN, 'GET')
      .then((response) => {
        if (response?.link_valid) {
          setIsTokenValid(true);
        } else {
          setIsTokenValid(false);
        }
      })
      .catch(() => {
        setIsTokenValid(false);
      });
  }, []);

  // // Таймаут для перенаправлення на сторінку recovery-password або signin
  // useEffect(() => {
  //   if (isTokenValid === false) {
  //     setTimeout(() => {
  //       push('/recovery-password');
  //     }, 5000);
  //   }
  // }, [isTokenValid, push]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setShowPasswordHint(value.length < 8 && value.length > 0);
  };

  const handlePasswordConfirmationChange = (e) => {
    const value = e.target.value;
    setPasswordConfirmation(value);
    setShowMismatchError(value && value !== password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      password,
      passwordConfirmation,
      code: getQuery,
    };

    apiInstance(ROUTES.PASSWORD, 'POST', payload)
      .then(() => {
        setErrorsInPost('');
        setIsSuccess('Пароль успішно змінено! Перенаправляємо на сторінку входу...');
        setTimeout(() => {
          push('/signin');
        }, 3000);
      })
      .catch(() => {
        setErrorsInPost('Помилка при зміні пароля.');
      });
  };

  const renderForm = useMemo(() => {
    const isSubmitDisabled =
      !password ||
      !passwordConfirmation ||
      password !== passwordConfirmation ||
      password.length < 8;

    return (
      <form onSubmit={handleSubmit} className="form recovery-password-form">
        <InputText
          type="password"
          label="Новий пароль"
          id="password"
          value={password}
          onChange={handlePasswordChange}
          error={showPasswordHint ? 'Пароль має містити не менше 8 символів.' : ''}
        />
        <InputText
          type="password"
          label="Повторіть пароль"
          id="passwordConfirmation"
          value={passwordConfirmation}
          onChange={handlePasswordConfirmationChange}
          error={showMismatchError ? 'Паролі не співпадають.' : ''}
        />
        <Button
          type="submit"
          text="Встановити"
          bg="pink"
          className="block"
          disabled={isSubmitDisabled}
        />
        {isSuccess && <div className="text-green-normal-f16-l28">{isSuccess}</div>}
        {errorsInPost && <div className="text-red-normal-f16-l28">{errorsInPost}</div>}
      </form>
    );
  }, [
    password,
    passwordConfirmation,
    showPasswordHint,
    showMismatchError,
    isSuccess,
    errorsInPost,
  ]);

  if (isTokenValid === null) {
    // Завантажуємо перевірку токена
    return <div>Завантаження...</div>;
  }

  if (isTokenValid === false) {
    return (
      <div className="signin">
        <div className="signin__col signin__col_form">
          <a href="/" className="signin__link">
            <img src="/images/logo.svg" alt="logo" />
          </a>
          <div className="signin__inner">
            {/* <div className="text-grey-dark-normal-f16-l28">
              Ваше посилання для скидання пароля більше не дійсне.
            </div> */}
            <Space direction="vertical" size="middle">
              <div className="text-black-bold-f40-l48">Посилання більше неактивне</div>
              <Button
                text="Відновити пароль повторно"
                bg="pink"
                className="block"
                onClick={() => push('/recovery-password')}
              />
              <Button
                text="Авторизуватись"
                bg="pink"
                className="block"
                onClick={() => push('/signin')}
              />
            </Space>
          </div>
        </div>
        <div className="signin__col signin__col_banner" />
      </div>
    );
  }

  return (
    <div className="signin">
      <div className="signin__col signin__col_form">
        <a href="/" className="signin__link">
          <img src="/images/logo.svg" alt="logo" />
        </a>
        <div className="signin__inner">
          <div className="text-black-bold-f40-l48">Пароль скинуто!</div>
          <div className="text-grey-dark-normal-f16-l28">
            Будь ласка, встановіть новий пароль для входу до кабінету орендаря.
          </div>
          {renderForm}
        </div>
      </div>
      <div className="signin__col signin__col_banner" />
    </div>
  );
};

export default ChangePassword;
