import { Table } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Svg from '../../components/SVG/SVG';
import { useContactStore } from '../../hooks/useContactStore';
import { apiInstance } from '../../utils/api';
import { downloadFile } from '../../utils/urlImgToBlob';

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    className: 'date',
  },
  {
    title: 'Номер рахунку',
    dataIndex: 'accountNumber',
    className: 'account-number center',
  },
  {
    title: 'Сума на сплату',
    dataIndex: 'amount',
    className: 'amount-sum center',
  },
  {
    title: 'Рахунок',
    dataIndex: 'score',
    className: 'score center',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    className: 'status center',
  },
];

const AccountTable = () => {
  const { currentContactStore } = useContactStore();
  const [counterparty, setCounterparty] = useState([]);

  const ROUTES = {
    COUNTERPARTY: `${process.env.REACT_APP_API_URL}/api/crm/get-data?type=requestInvoice`,
  };

  useEffect(() => {
    apiInstance(ROUTES.COUNTERPARTY, 'GET')
      .then((res) => setCounterparty(res.rows))
      .catch((error) => console.log(error));
    // console.log(counterparty);
  }, [currentContactStore]);

  const download = useCallback((id) => {
    const ROUTES = {
      FILE: new URL(`${process.env.REACT_APP_API_URL}/api/files?fileId=${id}&type=receipt`),
    };
    return downloadFile(ROUTES.FILE);
  }, []);

  const prepareRequestData = useMemo(() => {
    return counterparty.map(({ StartDate, Amount, FileId, Number, PaymentStatus }) => ({
      date: (
        <div className="f13-grey-dark">
          <div>{moment(StartDate).format('YYYY-MM-DD')}</div>
          <div className="f13-grey">{moment(StartDate).format('HH:mm')}</div>
        </div>
      ),
      accountNumber: <div className="f16-grey-dark">{Number}</div>,
      amount: <div className="f16-black">{Amount.toFixed(2)} грн</div>,
      score: FileId && (
        <div className="f16-black" onClick={() => download(FileId)}>
          Завантажити
          <Svg className="download" name="download" />
        </div>
      ),
      status: <div>{PaymentStatus.displayValue}</div>,
    }));
  }, [counterparty]);

  return (
    <Table
      className="default-table account-table request-table"
      columns={columns}
      dataSource={prepareRequestData}
      locale={{ emptyText: 'Дані Відсутні' }}
    />
  );
};

export default AccountTable;
