import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../../components/Button/Button';
import InputText from '../../components/Input/InputText';
import { AuthContext } from '../../Guard/AuthContext';
import { session } from '../../helpers/helpers';
import { apiInstance } from '../../utils/api';

const Profile = () => {
  const { user, setUser } = useContext(AuthContext);
  const token = session.get('token');
  const [errorsInPatch, setErrorsInPatch] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);

  const ROUTES = {
    GET_USER: new URL(`${process.env.REACT_APP_API_URL}/api/users/me?populate=*`),
    PUT_USER: new URL(`${process.env.REACT_APP_API_URL}/api/users/${user?.id}`),
  };

  useEffect(() => {
    apiInstance(ROUTES.GET_USER, 'GET')
      .then((user) => setUser(user))
      .catch((error) => console.log(error));
  }, [errorsInPatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = Array.from(e.target.elements).every((element) => {
      if (element.name && element.required && element.value.trim() === '') {
        return false; // Поле не заполнено
      }
      return true; // Поле заполнено
    });

    const passwordInput = e.target.elements.password;
    if (passwordInput.validity.patternMismatch) {
      setPasswordError(
        'Пароль повинен містити щонайменше 8 символів, включаючи хоча б одну цифру, одну малу та одну велику літеру.'
      );
      return;
    } else {
      setPasswordError(null); // Очищаем ошибку, если пароль валидный
    }

    if (!isFormValid) {
      alert('Пожалуйста, заполните все обязательные поля.'); // Или другой способ уведомления
      return;
    }
    const eventTargetElements = Array.from(e.target.elements);
    const payload = eventTargetElements.reduce((acc, { value, name }) => {
      if (!!name) {
        acc[name] = value;
      }

      return acc;
    }, {});

    apiInstance(ROUTES.PUT_USER, 'PUT', payload)
      .then((user) => {
        setUser(user);
        setIsSuccess(true);
        setErrorsInPatch([]);
      })
      .catch((error) => {
        setErrorsInPatch(error?.errors);
      });
  };

  const renderUserData = useMemo(() => {
    if (isEmpty(user)) return null;
    return (
      <div className="profile">
        <div className="boxes">
          <div className="profile-header">
            <img className="profile-header__avatar" src="/images/profile-avatar.png" alt="avatar" />

            <div className="profile-header__title">{user?.fullName}</div>
          </div>

          <div className="boxes__col left">
            <div className="box profile-box">
              <div className="profile-box-header">
                <div className="profile-box-header__title text-blue-medium-f22-l30">
                  Дані користувача
                </div>
              </div>

              <form onSubmit={handleSubmit} className="form field-grey-straight-border">
                <InputText
                  id="fullName"
                  value={user?.fullName}
                  label="ПІБ:"
                  // error={errorsInPatch['fullName'] && errorsInPatch['fullName'][0]}
                  disabled
                />
                <InputText
                  id="email"
                  value={user?.email}
                  label="Email:"
                  error={errorsInPatch['email'] && errorsInPatch['email'][0]}
                  disabled
                />
                <InputText
                  id="phone"
                  value={user?.phone}
                  label="Телефон:"
                  error={errorsInPatch['phone'] && errorsInPatch['phone'][0]}
                  disabled
                />
                <InputText
                  id="password"
                  label="Пароль:"
                  error={
                    passwordError || (errorsInPatch['password'] && errorsInPatch['password'][0])
                  } // Выводим ошибку пароля
                  required
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Пароль повинен містити щонайменше 8 символів, включаючи хоча б одну цифру, одну малу та одну велику літеру."
                  value={password} // Связываем значение с состоянием
                  onChange={(e) => setPassword(e.target.value)} // Обновляем состояние при изменении
                />

                <Button
                  type="submit"
                  text="Зберегти"
                  bg="turquoise"
                  block
                  disabled={!password} // Блокируем кнопку, если пароль не введен
                />

                {isSuccess && (
                  <div className="text-green-normal-f16-l28 ">
                    Дані профілю були успішно змінені
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }, [user, password, passwordError]);

  return renderUserData;
};

export default Profile;
