import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../Guard/AuthContext';
import { apiInstance } from '../utils/api';

export const useContactStore = () => {
  const location = useHistory();
  const { user, setUser, currentContactStore, setCurrentContactStore } = useContext(AuthContext);

  const [fetchedContactStores, setFetchedContactStores] = useState([]);
  const userContactStores = user?.contactStores; // тут могут быть поля { commodity, requests, accounts, marketing, current, crmId }

  // Fetch contact stores from backend и объединяем поля
  useEffect(() => {
    if (userContactStores && userContactStores.length > 0) {
      const filters = userContactStores
        .map((store, index) => `filters[crmId][${index}]=${store.crmId}`)
        .join('&');
      const url = `${process.env.REACT_APP_API_URL}/api/contact-stores?${filters}`;

      apiInstance(url, 'GET', null)
        .then((response) => {
          // объединяем данные из API + локальные поля userContactStores
          const stores = response.data.map((item) => {
            const crmId = item.attributes.crmId;
            // находим соответствие в userContactStores
            const matchedStore = userContactStores.find((cs) => cs.crmId === crmId);

            return {
              // сначала все поля из matchedStore (commodity, requests, accounts, etc.)
              ...matchedStore,
              // сверху кладем поля из item.attributes (name, createdAt, etc.)
              ...item.attributes,
            };
          });
          setFetchedContactStores(stores);
        })
        .catch((error) => {
          console.error('Ошибка при получении контактных магазинов:', error);
        });
    } else {
      setFetchedContactStores([]);
    }
  }, [userContactStores]);

  const handleChange = useCallback(
    async (crmId) => {
      // ищем в fetchedContactStores
      const newStore = fetchedContactStores?.find((store) => store.crmId === crmId);
      if (!newStore) {
        console.error('Контактный магазин с указанным ID не найден.');
        return;
      }

      setCurrentContactStore(newStore);

      try {
        await apiInstance(
          `${process.env.REACT_APP_API_URL}/api/user/change-contact-store?crmId=${newStore.crmId}`,
          'POST',
          null
        );

        // Перенаправление при необходимости
        if (
          location?.location?.pathname.includes('/requests/') ||
          location?.location?.pathname.includes('/edit-request/')
        ) {
          location.push('/');
        }
      } catch (error) {
        console.error('Ошибка при смене контактного магазина:', error);
      }
    },
    [fetchedContactStores, setCurrentContactStore, location]
  );

  // Собираем опции для селекта (или Dropdown)
  const currentContactStoreOptions = fetchedContactStores.map((store) => ({
    value: store.crmId,
    text: store.name, // или store?.Name
  }));

  return { currentContactStoreOptions, currentContactStore, handleChange };
};
