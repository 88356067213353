import { Form, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../components/Button/Button';
import Svg from '../../components/SVG/SVG';
import { session } from '../../helpers/helpers';
import { downloadFile } from '../../utils/urlImgToBlob';
import { PrepareSelect, prepareSelectOptions } from './RequestFormContentItem';

export const LoadingZone = ({ initialLoadingZone, requests, clickedSubmit, defaultValue }) => {
  const token = session.get('token');

  // Состояние для текущей зоны загрузки
  const [currentLoadingZone, setCurrentLoadingZone] = useState(
    defaultValue || initialLoadingZone || null
  );
  const [loadingZoneModal, setLoadingZoneModal] = useState(false);

  // Лог текущей зоны загрузки
  // console.log('Current Loading Zone ID:', currentLoadingZone);

  // Находим текущую зону загрузки по currentLoadingZone
  const currentLoadingZoneData = useMemo(() => {
    if (!requests?.SuLoadingZone || !currentLoadingZone) return null;
    const zone = requests.SuLoadingZone.find((zone) => zone.Id === currentLoadingZone);
    // console.log('Current Loading Zone Data:', zone);
    return zone;
  }, [requests?.SuLoadingZone, currentLoadingZone]);

  // Извлекаем URL изображений из currentLoadingZoneData
  const [imageLoadingZoneSmall, setImageLoadingZoneSmall] = useState(null);
  const [imageLoadingZoneFull, setImageLoadingZoneFull] = useState(null);

  useEffect(() => {
    if (
      currentLoadingZoneData &&
      currentLoadingZoneData.ZoneImage &&
      currentLoadingZoneData.ZoneImage.length > 0
    ) {
      const imageData = currentLoadingZoneData.ZoneImage[0];
      const baseUrl = process.env.REACT_APP_API_URL || '';
      const formats = imageData.formats;

      // Получаем URL уменьшенного изображения
      const smallImage =
        (formats?.medium?.url && `${baseUrl}${formats.medium.url}`) ||
        (formats?.small?.url && `${baseUrl}${formats.small.url}`) ||
        null;

      // Получаем полный URL изображения
      const fullImage = imageData.url ? `${baseUrl}${imageData.url}` : null;

      setImageLoadingZoneSmall(smallImage);
      setImageLoadingZoneFull(fullImage);

      // Логирование URL изображений
      // console.log('Small Image URL:', smallImage);
      // console.log('Full Image URL:', fullImage);
    } else {
      setImageLoadingZoneSmall(null);
      setImageLoadingZoneFull(null);
    }
  }, [currentLoadingZoneData]);

  const handleClickLoadingZone = () => {
    setLoadingZoneModal(true);
  };

  const renderSuLoadingZoneItems = useMemo(() => {
    if (!requests?.SuLoadingZone) return null;
    const SuLoadingZoneArray = Object.values(requests?.SuLoadingZone);
    const sortingSuLoadingZoneArray = SuLoadingZoneArray.sort((a, b) => {
      if (a.SerialNumber < b.SerialNumber) {
        return -1;
      }
      if (a.SerialNumber > b.SerialNumber) {
        return 1;
      }
      return 0;
    });

    // console.log('Sorted Loading Zones:', sortingSuLoadingZoneArray);
    // console.log('Default Value for Select:', currentLoadingZone);

    return (
      <PrepareSelect
        defaultValue={defaultValue || initialLoadingZone} // Начальное значение
        value={currentLoadingZone} // Текущее значение
        options={prepareSelectOptions(sortingSuLoadingZoneArray)} // Опции выбора
        placeholder="Вкажіть зону завантаження"
        onChange={(value) => {
          // console.log('Selected Loading Zone ID:', value);
          setCurrentLoadingZone(value); // Обновляем текущую зону загрузки
        }}
        disabledForm={clickedSubmit}
      />
    );
  }, [
    requests?.SuLoadingZone,
    currentLoadingZone,
    defaultValue,
    initialLoadingZone,
    clickedSubmit,
  ]);

  return (
    <>
      <Form.Item
        rules={[{ required: true, message: 'Оберіть зону завантаження' }]}
        name="SuLoadingZone"
        label="Зона завантаження"
      >
        {renderSuLoadingZoneItems}
      </Form.Item>

      {imageLoadingZoneSmall && (
        <img
          className="loading-zone-img"
          src={imageLoadingZoneSmall}
          alt="road_map"
          title="Відкрийте щоб завантажити"
          onClick={handleClickLoadingZone}
        />
      )}

      <Modal
        className="modal-type-green-close"
        visible={loadingZoneModal}
        footer={null}
        onCancel={() => setLoadingZoneModal(false)}
        closeIcon={<Svg name="close-modal" />}
      >
        <div className="modal-type-green-close__wrapper">
          {imageLoadingZoneFull && (
            <img className="loading-zone-img-modal" src={imageLoadingZoneFull} alt="road_map" />
          )}
          <Button
            type="submit"
            text="Завантажити"
            bg="pink"
            disabled={clickedSubmit}
            onClick={() => downloadFile(imageLoadingZoneFull, token)}
          />
        </div>
      </Modal>
    </>
  );
};
