import cx from 'classnames';
import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import './assets/styles/styles.scss';
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { session } from './helpers/helpers';
import Routes from './Routes';

const checkRoute = (pathname) => {
  const getPath = pathname.split('/')[1];

  if (pathname === '/') return 'landing-page';

  return `${getPath}-page`;
};

const AppLayout = () => {
  const { pathname } = useLocation();
  const token = session.get('token');

  const pageClassName = cx('page', checkRoute(pathname));

  return (
    <div className={pageClassName}>
      {token && <Sidebar />}

      <section className="page__container">
        {token && <Header />}
        <div className="page__content">
          {token && <Banner />}
          <Switch>
            <Routes />
          </Switch>
          {token && <Footer />}
        </div>
      </section>
    </div>
  );
};

export default AppLayout;
